import { CSS, styled } from '@/stitches.config';
import { PortableTextListComponent } from '@portabletext/react';
import React, { ComponentPropsWithoutRef } from 'react';

type UnorderedListProps = Partial<
  ComponentPropsWithoutRef<PortableTextListComponent>
> & {
  css?: CSS;
};

export function UnorderedList({ children, css }: UnorderedListProps) {
  return <Ul css={css}>{children}</Ul>;
}

const Ul = styled('ul', {
  fontSize: '$2',
  lineHeight: '$7',
  ml: '0',
  mb: '$6',
  // listStylePosition: 'inside',
  // listStyleType: 'circle',
  '&:not(:has(+ *))': {
    mb: 0,
  },
  '@bp4': {
    fontSize: '$3',
  },
  li: {
    paddingLeft: '$6',
    position: 'relative',
    '&:before': {
      content: '',
      width: '0.44em',
      height: '0.44em',
      borderRadius: '50%',
      border: '1px solid currentColor',
      position: 'absolute',
      top: '0.35em',
      left: 0,
    },

    mb: '$1',
    '&:last-of-type': {
      mb: 0,
    },
  },
});

import { Service } from '@/types/sanity';
import { Box } from '../box';
import { ArrowRight } from '../icons';
import { TreemapImage } from '../treemap-image';
import { urlForImage } from '@/lib/sanity/sanity';
import { useState } from 'react';
import Link from 'next/link';
import { styled } from '@/stitches.config';
import { ProjectLinkTitle } from './project-link-title';
import { SolutionExternalLink } from '../icons/solution-external-link';

interface SolutionCardProps {
  solutionData: Service['solutions'][number];
}

export function SolutionCard({ solutionData }: SolutionCardProps) {
  const [isActive, setIsActive] = useState(false);

  return (
    <Link
      href={
        solutionData.linkType === 'project'
          ? `/project/${solutionData.projectSlug}`
          : solutionData.url ?? '/'
      }
      passHref
    >
      <Box
        as="a"
        onPointerEnter={() => setIsActive(true)}
        onPointerLeave={() => setIsActive(false)}
        onFocus={() => setIsActive(true)}
        onBlur={() => setIsActive(false)}
        target={solutionData.linkType === 'url' ? '_blank' : undefined}
        rel={solutionData.linkType === 'url' ? 'noreferrer' : undefined}
        css={{ display: 'block' }}
      >
        {/* Top box with titles */}
        <Box
          css={{
            position: 'relative',
            height: '7rem',
            '@bp6': {
              height: '7.9rem',
            },
            '@bp7': {
              height: '8.75rem',
            },
          }}
        >
          {/* Idle title */}
          <Box
            css={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              padding: '$4',
              backgroundColor: '$background',
              color: '$foreground',
              border: '1px solid $borderColor',
              borderBottom: 'none',
              '& svg': {
                marginBottom: '-0.4rem',
              },
            }}
          >
            <TitleText>{solutionData.title}</TitleText>
            {solutionData.linkType === 'project' ? (
              <ArrowRight width={24} height={24} />
            ) : (
              <SolutionExternalLink
                width={20}
                height={20}
                style={{ transform: 'translateY(-0.25rem)' }}
              />
            )}
          </Box>

          {/* Hover title */}
          <Box
            css={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              padding: '$4',
              backgroundColor: '$foreground',
              color: '$background',
              clipPath: isActive
                ? 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)'
                : 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
              transition: 'clip-path 0.5s',
            }}
          >
            <TitleText>{solutionData.subheading}</TitleText>
          </Box>
        </Box>
        <Box
          css={{
            position: 'relative',
            aspectRatio: '1 / 1',
          }}
        >
          <TreemapImage
            src={urlForImage(solutionData.thumbnail.asset).url()}
            palette={solutionData.thumbnail.asset.metadata.palette}
            layout="fill"
            animationState={isActive ? 'visible' : 'hidden'}
          />
          <ProjectLinkTitle
            isVisible={isActive}
            title={solutionData.projectLinkTitle}
            isExternalLink={solutionData.linkType === 'url'}
          />
        </Box>
      </Box>
    </Link>
  );
}

const TitleText = styled('span', {
  display: 'inline-block',
  maxWidth: '250px',
  fontSize: '$2',
  lineHeight: '$6',
  '@bp6': {
    fontSize: '$3',
  },
  '@bp7': {
    fontSize: '$4',
  },
});

import { styled } from '@/stitches.config';
import { focusStateStyles, Underline, underlineHoverStyles } from './underline';

export interface LinkProps {
  children: React.ReactNode;
  target?: string;
  value?: {
    href?: string;
  };
}

export function Anchor(props: LinkProps) {
  const { children, target, value } = props;

  if (!value) return null;
  if (!value.href) {
    console.warn('Found an anchor with a missing href:', children, value);
  }

  const rel = !value?.href?.startsWith('/') ? 'noreferrer noopener' : undefined;

  return (
    <StyledA
      href={value.href}
      rel={rel}
      target={target}
      underline={
        !(value?.href?.includes('mailto:') || value?.href?.includes('tel:'))
      }
    >
      {children}
      <Underline />
    </StyledA>
  );
}

const StyledA = styled('a', {
  color: '$foreground',
  display: 'inline-flex',
  margin: '0 !important',
  position: 'relative',
  textDecoration: 'underline',
  textDecorationThickness: 2,

  overflow: 'hidden',

  '&:hover': {
    textDecoration: 'none',
  },
  variants: {
    underline: {
      true: {
        textDecoration: 'underline',
        textDecorationThickness: 2,
        '&:hover': {
          textDecoration: 'none',
        },
      },
      false: {
        textDecoration: 'none',
        '&:hover, &:active': {
          [`${Underline} > span`]: {
            ...underlineHoverStyles,
            animationDuration: '300ms',
          },
        },
        '&:focus-visible': {
          ...focusStateStyles,
          outlineOffset: '2px',
          [`${Underline} > span`]: {
            ...underlineHoverStyles,
            animationDuration: '300ms',
          },
        },
      },
    },
  },
});

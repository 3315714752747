import { motion } from 'framer-motion';
import { Box } from '../box';
import { ArrowRight } from '../icons';
import { Text } from '../text';
import { SolutionExternalLink } from '../icons/solution-external-link';

interface ProjectLinkTitleProps {
  isVisible: boolean;
  title: string;
  isExternalLink: boolean;
}

export function ProjectLinkTitle({
  isVisible,
  title,
  isExternalLink,
}: ProjectLinkTitleProps) {
  return (
    <Box
      as={motion.div}
      css={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        padding: '$4',
        paddingBlockStart: '$11',
        color: 'white',
        '& svg': {
          marginTop: '0.125rem',
        },
      }}
      animate={{
        opacity: isVisible ? 1 : 0,
        transition: {
          delay: isVisible ? 0.5 : 0,
          duration: isVisible ? 0.2 : 0.2,
        },
      }}
    >
      <Box
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          // This is smoother than a regular linear gradient.
          // Copied from https://css-tricks.com/easing-linear-gradients/
          background: `linear-gradient(
            to top,
            hsl(0, 0%, 0%) 0%,
            hsla(0, 0%, 0%, 0.738) 19%,
            hsla(0, 0%, 0%, 0.541) 34%,
            hsla(0, 0%, 0%, 0.382) 47%,
            hsla(0, 0%, 0%, 0.278) 56.5%,
            hsla(0, 0%, 0%, 0.194) 65%,
            hsla(0, 0%, 0%, 0.126) 73%,
            hsla(0, 0%, 0%, 0.075) 80.2%,
            hsla(0, 0%, 0%, 0.042) 86.1%,
            hsla(0, 0%, 0%, 0.021) 91%,
            hsla(0, 0%, 0%, 0.008) 95.2%,
            hsla(0, 0%, 0%, 0.002) 98.2%,
            hsla(0, 0%, 0%, 0) 100%
          )`,
          opacity: 0.5,
        }}
      />
      <Box
        css={{
          display: 'flex',
          alignItems: 'center',
          gap: '$2',
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Text>{title}</Text>
        {isExternalLink ? (
          <SolutionExternalLink width={12} />
        ) : (
          <ArrowRight width={14} />
        )}
      </Box>
    </Box>
  );
}

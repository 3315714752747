import { GalleryProps } from '../serializers/gallery';
import { TreemapImage } from '../treemap-image';
import { CaptionOnHover } from '../caption-on-hover';

interface CollageItemProps {
  image: GalleryProps['images'][number];
}

export function CollageItem({ image }: CollageItemProps) {
  if (!image.caption) {
    throw Error("Image doesn't contain caption.");
  }

  return (
    <CaptionOnHover caption={image.caption}>
      <TreemapImage
        src={image.asset.url}
        palette={image.asset.metadata.palette}
        layout="fill"
      />
    </CaptionOnHover>
  );
}

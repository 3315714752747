import { SVG } from './types';

export function SolutionExternalLink(props: SVG) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3H9V5H2V19H16V12H18V21H0V3Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.547 0.0897023L20.67 0.0329565L20.6133 10.156L18.7671 10.1456L18.806 3.20234L12.7467 9.26165L9.71708 12.2913L8.41165 10.9859L11.4413 7.95622L17.5006 1.89691L10.5573 1.93585L10.547 0.0897023Z"
        fill="currentColor"
      />
    </svg>
  );
}

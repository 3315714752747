import { Service } from '@/types/sanity';
import { Box } from '../box';
import { Minus, PlusThin } from '../icons';
import { Text } from '../text';
import { TreemapImage } from '../treemap-image';
import { urlForImage } from '@/lib/sanity/sanity';
import { useState } from 'react';
import Link from 'next/link';
import { useDimensions } from '@/hooks/use-dimensions';
import { ProjectLinkTitle } from './project-link-title';
import { styled } from '@/stitches.config';

interface SolutionAccordionItemProps {
  solutionData: Service['solutions'][number];
}

export function SolutionAccordionItem({
  solutionData,
}: SolutionAccordionItemProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { setElement: setWrapperElement, width: wrapperWidth } =
    useDimensions();
  const { setElement: setSubheaderElement, width: subheaderWidth } =
    useDimensions();
  const {
    setElement: setExpandedDropdownElement,
    height: expandedDropdownHeight,
  } = useDimensions();

  const expandedImageWidth =
    wrapperWidth && subheaderWidth ? wrapperWidth - subheaderWidth : null;

  const alignVertically = expandedImageWidth ? expandedImageWidth < 300 : true;

  const renderPhotoSection = () => (
    <Link
      href={
        solutionData.linkType === 'project'
          ? `/project/${solutionData.projectSlug}`
          : solutionData.url ?? '/'
      }
      passHref
    >
      <Box
        as="a"
        target={solutionData.linkType === 'url' ? '_blank' : undefined}
        rel={solutionData.linkType === 'url' ? 'noreferrer' : undefined}
        css={{ display: 'block' }}
      >
        <Box
          css={
            alignVertically
              ? {
                  position: 'relative',
                  width: '100%',
                  aspectRatio: '1 / 1',
                }
              : {
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  aspectRatio: '1 / 1',
                  height: '100%',
                  transition: 'height 1s',
                }
          }
        >
          <TreemapImage
            src={urlForImage(solutionData.thumbnail.asset).url()}
            palette={solutionData.thumbnail.asset.metadata.palette}
            layout="fill"
            animationState={isOpen ? 'visible' : 'hidden'}
          />
          <ProjectLinkTitle
            isVisible={isOpen}
            title={solutionData.projectLinkTitle}
            isExternalLink={solutionData.linkType === 'url'}
          />
        </Box>
      </Box>
    </Link>
  );

  return (
    <Box
      ref={setWrapperElement}
      css={{
        display: 'flex',
        position: 'relative',
        $$dividerColor: isOpen ? 'transparent' : '$colors$borderColor',
        $$collapsedHeight: '80px',
        $$expandedHeight: alignVertically
          ? `${expandedDropdownHeight}px`
          : `min(${expandedImageWidth}px, 50vw, 50vh)`,
        height: isOpen ? '$$expandedHeight' : '$$collapsedHeight',
        transition: 'height 1s, border-color 1s',
        overflow: 'hidden',
        borderTop: '2px solid $$dividerColor',
        boxSizing: 'content-box',
        '&:last-child': {
          borderBottom: '2px solid $$dividerColor',
        },
        '& *': {
          boxSizing: 'border-box',
        },
      }}
      onClick={isOpen ? undefined : () => setIsOpen(true)}
    >
      <Box
        css={{
          display: 'flex',
          alignItems: 'center',
          position: alignVertically ? 'relative' : 'absolute',
          top: 0,
          left: 0,
          width: 'calc(100% - $$collapsedHeight)',
          height: '$$collapsedHeight',
          paddingInline: '$5',
          background: '$background',
          cursor: 'pointer',
          '@bp3': {
            paddingInlineStart: 0,
          },
        }}
      >
        <Title color="foreground">{solutionData.title}</Title>
      </Box>
      <Box
        css={{
          visibility: alignVertically ? 'hidden' : undefined,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '$$expandedHeight',
          background: '$foreground',
          clipPath: isOpen
            ? 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)'
            : 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)',
          transition: 'clip-path 1s',
        }}
      >
        <Box
          ref={setSubheaderElement}
          css={{
            position: 'absolute',
            top: 0,
            left: 0,
            padding: '$6',
            paddingRight: '$8',
            maxWidth: 'min(70%, 500px)',
          }}
        >
          <Title>{solutionData.subheading}</Title>
        </Box>
      </Box>

      {alignVertically ? (
        <Box
          ref={setExpandedDropdownElement}
          css={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            background: '$foreground',
            clipPath: isOpen
              ? 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)'
              : 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
            transition: 'clip-path 0.5s',
            cursor: 'pointer',
          }}
        >
          <Box
            css={{
              display: 'flex',
              alignItems: 'center',
              padding: '$5',
              paddingRight: 'calc($$collapsedHeight)',
              minHeight: '$$collapsedHeight',
            }}
            onClick={() => setIsOpen(false)}
          >
            <Title>{solutionData.subheading}</Title>
          </Box>
          {renderPhotoSection()}
        </Box>
      ) : (
        renderPhotoSection()
      )}
      <Box
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'absolute',
          top: 0,
          right: 0,
          height: '$$collapsedHeight',
          aspectRatio: '1 / 1',
          cursor: 'pointer',
        }}
      >
        <PlusThin width={24} />
        <Box
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            color: '$background',
            backgroundColor: '$foreground',
            clipPath: isOpen
              ? 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)'
              : 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)',
            transition: 'clip-path 0.5s',
          }}
          onClick={() => setIsOpen(false)}
        >
          <Minus width={24} />
        </Box>
      </Box>
    </Box>
  );
}

const Title = styled(Text, {
  fontSize: '$4',
  lineHeight: '$6',
  variants: {
    color: {
      foreground: {
        color: '$foreground',
      },
      background: {
        color: '$background',
      },
    },
  },
  defaultVariants: { color: 'background' },
});

import 'wicg-inert'; // This polyfills the inert attribute for browsers with (incomplete) support

let closeCallback: (() => void) | undefined;

function disableScroll() {
  document.scrollingElement?.classList.add('disable-scroll');
}

function enableScroll() {
  document.scrollingElement?.classList.remove('disable-scroll');
}

// Makes sure the rest of the page is not scrollable and inert to assistive technologies
export function openModal(modalElement: HTMLElement, close: () => void) {
  if (closeCallback) {
    throw new Error(
      'A modal is already open. Only one modal can be open at a time'
    );
  }

  disableScroll();
  document.addEventListener('keydown', onEsc);

  // Make the rest of the page inert to assistive technologies
  makeElementsInert(document.documentElement, modalElement);

  // This makes sure the back button will also close the modal
  history.pushState({ modal: 'closed' }, '', window.location.href);
  window.addEventListener('popstate', close);

  closeCallback = close;
}

export function closeModal() {
  if (!closeCallback) return;

  enableScroll();
  document.removeEventListener('keydown', onEsc);
  window.removeEventListener('popstate', closeCallback);

  // Reset state of the rest of the page
  const inertElements = document.querySelectorAll<HTMLElement>('[inert]');
  inertElements.forEach((el) => {
    const inertableElement = el;

    if (inertableElement.inert) {
      inertableElement.inert = false;
    }
  });

  closeCallback = undefined;

  // Remove our custom history state if it's still there (happens if the modal
  // was closed through other means than the back button)
  if (history.state && history.state.modal === 'closed') {
    history.back();
  }
}

function onEsc(e: KeyboardEvent) {
  if (e.key === 'Escape' && closeCallback) {
    closeCallback();
  }
}

// Recursively walks through children of the given parent element, making them
// inert if they do not contain the excluded element
function makeElementsInert(
  parentElement: HTMLElement,
  excludedElement: HTMLElement
) {
  if (parentElement === excludedElement) return;

  const children = parentElement.children;

  for (const child of children) {
    if (!(child instanceof HTMLElement)) continue;

    const inertableChild = child;

    if (!inertableChild.contains(excludedElement)) {
      inertableChild.inert = true;
    } else {
      makeElementsInert(inertableChild, excludedElement);
    }
  }
}
